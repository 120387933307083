import { Navigate, createBrowserRouter } from "react-router-dom";

import {
  DEVICES_CREATEDEVICE,
  DEVICES_EDITDEVICE,
  DEVICES_OVERVIEW,
  DEVICES_VIEWDEVICE,
  LEVELS_CREATE,
  LEVELS_EDIT,
  LEVELS_VIEW,
  POINTGROUPS_CREATEGROUP,
  POINTGROUPS_EDITGROUPSETTINGS,
  POINTGROUPS_EDITPOINTS,
  POINTGROUPS_OVERVIEW,
  POINTGROUPS_VIEWGROUP,
} from "../routes/route-paths";
import { RootRouteError } from "../common/root-route-error";
import { ProtectedRoute } from "../common/protected-route";
import { PageLayout } from "../common/page-layout";
import { RootRoute } from "./root";

/**
 * This is the way to define routes with React Router v6.4 and higher.
 * It enables the data APIs like loaders, actions, fetchers and more.
 * See: https://reactrouter.com/en/main/routers/create-browser-router
 */
export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <RootRouteError />,
    children: [
      /**
       * We dont' render anything in the root route `/` so we want to redirect
       * the user to the protected `/company` url segment that will handle the
       * automatic redirect to the first available company if the user is authorized.
       */
      {
        index: true,
        element: <Navigate to="company" replace />,
      },
      {
        path: "auth/callback",
        lazy: () => import("./authenticated-callback"),
      },
      {
        path: "auth/unauthorized",
        lazy: () => import("./unauthorized"),
      },
      {
        path: "logout",
        lazy: () => import("./logout"),
      },
      /**
       * All the main routes of the app are under the `/company` url segment.
       */
      {
        path: "company",
        element: <ProtectedRoute />,
        children: [
          /**
           * Redirect to the first available company if the `:companyId` param
           * is not present in the URL.
           */
          {
            index: true,
            lazy: () => import("./redirect-to-company"),
          },
          {
            path: ":companyId",
            element: <PageLayout />, // this wraps all child routes
            children: [
              /**
               * Redirect to `/devices` if user lands on `/company/:companyId`
               * since that is the "home" page of the app.
               */
              {
                index: true,
                element: <Navigate to={`${DEVICES_OVERVIEW}`} replace />,
              },

              // Devices

              {
                path: `${DEVICES_OVERVIEW}/:id?`,
                lazy: () => import("../devices/routes/device-overview.route"),
              },
              {
                path: `${DEVICES_VIEWDEVICE}/:id`,
                lazy: () => import("../devices/routes/device-view.route"),
              },
              {
                path: `${DEVICES_EDITDEVICE}/:id`,
                lazy: () => import("../devices/routes/device-edit.route"),
              },
              {
                path: `${DEVICES_CREATEDEVICE}/:id`,
                lazy: () => import("../devices/routes/device-create.route"),
              },

              // Levels

              {
                path: `${LEVELS_VIEW}/:id`,
                lazy: () => import("../levels/routes/level-view.route"),
              },
              {
                path: `${LEVELS_CREATE}/:id?`,
                lazy: () => import("../levels/routes/level-create.route"),
              },
              {
                path: `${LEVELS_EDIT}/:id`,
                lazy: () => import("../levels/routes/level-edit.route"),
              },

              // Point groups

              {
                path: `${POINTGROUPS_OVERVIEW}`,
                lazy: () =>
                  import("../pointgroups/routes/point-group-overview.route"),
              },
              {
                path: `${POINTGROUPS_CREATEGROUP}`,
                lazy: () =>
                  import("../pointgroups/routes/point-group-create.route"),
              },
              {
                path: `${POINTGROUPS_VIEWGROUP}/:id`,
                lazy: () =>
                  import("../pointgroups/routes/point-group-view.route"),
              },
              {
                path: `${POINTGROUPS_EDITGROUPSETTINGS}/:id`,
                lazy: () =>
                  import(
                    "../pointgroups/routes/point-group-edit-settings.route"
                  ),
              },
              {
                path: `${POINTGROUPS_EDITPOINTS}/:id`,
                lazy: () =>
                  import("../pointgroups/routes/point-group-edit-points.route"),
              },
              /**
               * This catch-all route is rendered if none of the above routes match
               */
              {
                path: "*",
                lazy: () => import("./not-found"),
              },
            ],
          },
        ],
      },
      /**
       * This catch-all route is rendered if none of the above routes match
       */
      {
        path: "*",
        lazy: () => import("./not-found"),
      },
    ],
  },
]);
