import { queryOptions, useQuery } from "@tanstack/react-query";
import { adminApiRequest } from "../auth/api-request";
import { ApiResponse } from "../consts";
import { Company } from "../models/company.model";
import { useRequiredParams } from "../utils/url";

export const companyQueries = {
  all: queryOptions({
    queryKey: ["companies"],
    queryFn: fetchCompanies,
  }),
  detail: (id: number) =>
    queryOptions({
      queryKey: ["companies", id],
      queryFn: () => fetchCompany(id),
    }),
};

// Queries

export function useCompaniesQuery() {
  return useQuery(companyQueries.all);
}

export function useCurrentCompanyQuery() {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(companyQueries.detail(companyId));
}

// Fetchers

async function fetchCompanies() {
  return adminApiRequest
    .get<ApiResponse<Company[]>>("api/internal/companies", {
      params: { permission: "assets" },
    })
    .then((resp) => resp.data.data.map((d) => new Company(d)));
}

async function fetchCompany(id: number) {
  return adminApiRequest
    .get<ApiResponse<Company>>(`api/internal/companies/${id}`, {
      params: { permission: "assets" },
    })
    .then((resp) => new Company(resp.data.data));
}
