import { Outlet } from "react-router-dom";
import { useCurrentUserQuery } from "../user/user.queries";

/**
 * The concept of a protected route is to prevent unauthorized users from
 * accessing the route. This is done by simply trying to fetch the current user
 * and if the user is NOT logged in, the user will be automatically redirected
 * to the login page by the http client.
 *
 * See `redirectToLogin` method of `Http` class in `http.ts` for more details.
 */
export function ProtectedRoute() {
  const { isFetched } = useCurrentUserQuery();
  if (!isFetched) return null; // show nothing while we are fetching
  return <Outlet />;
}
