import { useLingui } from "@lingui/react";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";
import { Navbar } from "../topnavi/nav-bar";

export function PageLayout() {
  useLingui();

  return (
    <Layout>
      <Navbar />
      <Main>
        <Outlet />
      </Main>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: ${(p) => p.theme.colors.mutedNeutralBackground};

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.white};
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
