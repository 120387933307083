import { config } from "../config";

const fxfiAccessToken = "fxfi-access-token";
const fxfiRefreshToken = "fxfi-refresh-token";
const fxfiRefreshUrl = "fxfi-refresh-url";

export type Token = {
  access_token: string;
  refresh_token: string;
};

export function saveToken(token: {
  accessToken: string;
  refreshToken: string;
}) {
  sessionStorage.setItem(fxfiAccessToken, token.accessToken);
  sessionStorage.setItem(fxfiRefreshToken, token.refreshToken);
}

export function getAccessToken() {
  const token = sessionStorage.getItem(fxfiAccessToken);
  return token;
}

export function getRefreshToken() {
  return sessionStorage.getItem(fxfiRefreshToken);
}

export function isAuthenticated() {
  const token = sessionStorage.getItem(fxfiAccessToken);
  return !!token;
}
export const removeSession = () => {
  sessionStorage.removeItem(fxfiAccessToken);
  sessionStorage.removeItem(fxfiRefreshToken);
  sessionStorage.removeItem(fxfiRefreshUrl);
};

export const logout = () => {
  removeSession();
  window.location.href = `${config.ADMIN_URL}/logout?redirectUrl=${config.BASE_URL}`;
};
