export const POINTGROUPS_OVERVIEW = `settings`;
export const POINTGROUPS_VIEWGROUP = `settings/pointgroups/view`;
export const POINTGROUPS_EDITGROUPSETTINGS = `settings/pointgroups/editgroup`;
export const POINTGROUPS_CREATEGROUP = `settings/pointgroups/create`;
export const POINTGROUPS_EDITPOINTS = `settings/pointgroups/editpoints`;

export const DEVICES_VIEWDEVICE = `devices/view`;
export const DEVICES_CREATEDEVICE = `devices/create`;
export const DEVICES_EDITDEVICE = `devices/edit`;
export const DEVICES_OVERVIEW = `devices`;

export const LEVELS_CREATE = `levels/create`;
export const LEVELS_EDIT = `levels/edit`;
export const LEVELS_VIEW = `levels/view`;
