import { styled } from "styled-components";

import { AvatarButton, Icon, Menu, Stack } from "@fidelix/fx-miranda";
import { useCurrentUserQuery } from "../user/user.queries";

export function NavMenu() {
  const { data: user } = useCurrentUserQuery();

  return (
    <MenuBase trigger={<AvatarButton name={user?.fullName ?? ""} />}>
      <Menu.Item href="/logout">
        <Stack axis="x" spacing="xsmall" align="center">
          <Icon icon="logOut" size={18} />
          <span>Logout</span>
        </Stack>
      </Menu.Item>
    </MenuBase>
  );
}

const MenuBase = styled(Menu)`
  min-width: 250px;
`;
