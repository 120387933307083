import { useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

/**
 * Hook to get typesafe and properly casted params from the URL.
 * You can use this hook instead of `useParams` from React Router when you
 * need to access to url params that you know are defined based on the route
 * context, eg. if you are in a route like `/companies/:companyId` you know
 * that `companyId` will always be defined.
 *
 * @example
 * const { companyId } = useRequiredParams({ companyId: "number" });
 */
export function useRequiredParams<
  T extends Record<string, "string" | "number">,
>(options: T) {
  const params = useParams();

  // Validate and cast params based on the provided paramTypes
  const castedParams = Object.entries(options).reduce((acc, [key, type]) => {
    const paramValue = params[key];

    // Check if the param exists
    if (paramValue === undefined || paramValue === null) {
      throw new Error(`Missing required parameter: ${key}`);
    }

    // Attempt to cast the param to the specified type
    acc[key] = type === "number" ? Number(paramValue) : paramValue;

    return acc;
  }, {});

  return castedParams as {
    [K in keyof T]: T[K] extends "number" ? number : string;
  };
}

export function useNavigationPath() {
  const location = useLocation();
  const params = useRequiredParams({ companyId: "number" });
  const to = (path: string) => `/company/${params.companyId}/${path}`;
  const isActive = (path: string) => location.pathname.includes(to(path));
  return { to, isActive };
}

/**
 * A utility hook to get and update URL search params and keep them in sync with
 * session storage so that they are persisted across page navigations.
 *
 * @example
 *
 * ```tsx
 * const {
 *   searchParams,
 *   setSearchParams
 * } = usePersistedSearchParams("level-tree");
 *
 * const openLevels = searchParams.getAll("open");
 *
 * function handleLevelSelect(levelId: number) {
 *   setSearchParams((params) => {
 *     params.set("level", String(levelId));
 *     params.set("page", "1");
 *     params.delete("device");
 *   });
 * }
 * ```
 */
export function usePersistedSearchParams(key: string) {
  const { companyId } = useRequiredParams({ companyId: "string" });
  const persistKey = `persisted-search-params:${companyId}-${key}`;
  const persistedParams = sessionStorage.getItem(persistKey);

  /**
   * Restore persisted search params from sessionStorage if they exist and there
   * are no existing search params in the URL.
   * The URL params always have precedence over persisted params so that
   * it is possible to come to the page via a link that has params or to override
   * the persisted params by manually modifying them in the URL.
   *
   * NOTE: we don't want to use `useEffect` here because it would cause the
   * component to render with incorrect params on first render.
   */
  let initialParams: URLSearchParams | undefined;

  if (!window.location.search && persistedParams) {
    initialParams = new URLSearchParams(persistedParams);
    // Update the URL to match the persisted params
    window.location.search = initialParams.toString();
  }

  const [params, setParams] = useSearchParams(initialParams);

  function setSearchParams(handler: (prev: URLSearchParams) => void) {
    setParams((params) => {
      handler(params);
      sessionStorage.setItem(persistKey, params.toString());
      return params;
    });
  }

  function removeSearchParam(key: string) {
    setSearchParams((prev) => {
      prev.delete(key);
      sessionStorage.setItem(persistKey, prev.toString());
      return prev;
    });
  }

  function clearSearchParams() {
    setParams("");
    sessionStorage.removeItem(persistKey);
  }

  // Persist initial search params to session storage
  useEffect(() => {
    sessionStorage.setItem(persistKey, params.toString());
  }, [persistKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    searchParams: params,
    setSearchParams,
    removeSearchParam,
    clearSearchParams,
  };
}
