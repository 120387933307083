export class User {
  permissions: string[];
  id: number;
  email: string;
  createdAt: Date;
  active: boolean;
  firstName: string | null;
  lastName: string | null;

  constructor(user: User) {
    this.permissions = user.permissions;
    this.id = user.id;
    this.email = user.email;
    this.createdAt = new Date(user.createdAt);
    this.active = user.active;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
  }

  get fullName() {
    return `${this.firstName || ""} ${this.lastName || ""}`.trim();
  }
}
