import { useState } from "react";
import { Select } from "@fidelix/fx-miranda";
import { styled } from "styled-components";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useRequiredParams } from "../utils/url";
import { useCompaniesQuery } from "./company.queries";

export function CompanySelect() {
  const { companyId } = useRequiredParams({ companyId: "string" });
  const { data: companies = [] } = useCompaniesQuery();
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const navigate = useNavigate();
  const location = useLocation();

  function handleSelect(companyId: string) {
    const match = matchPath("/company/:companyId/*", location.pathname);
    const route = match?.params["*"] || "devices";
    setSelectedCompany(companyId);
    navigate(`/company/${companyId}/${route}`);
  }

  return (
    <NavSelect
      data-test-id="company-select"
      aria-label="Companies"
      selected={selectedCompany}
      onSelect={handleSelect}
      // NOTE: this is intentionally empty to avoid flicker of the placeholder
      // when the companies are still loading
      placeholder=" "
    >
      {companies.map((company) => (
        <Select.Option key={company.id} value={String(company.id)}>
          {company.name}
        </Select.Option>
      ))}
    </NavSelect>
  );
}

const NavSelect = styled(Select)`
  button {
    background-color: ${(p) => p.theme.colors.white};
    width: 160px;
  }
`;
